import React from "react";
import { concatClassNames as cn } from "@sys42/utils";

import Layout from "../../layouts/layout";

import { HeaderTitle } from "../../components/HeaderTitle";
import { HtmlContent } from "../../components/HtmlContent";
import { WavySeparator } from "../../components/WavySeparator";

import * as helperStyles from "../helpers.module.css";

const Sales = () => (
  <Layout title={"Customer Support Specialist"}>
    <article>
      <HeaderTitle titleWidth={"13em"}>Customer Support Specialist</HeaderTitle>

      <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

      <div className={helperStyles.container}>
        <HtmlContent
          className={cn(helperStyles.spacingMd, helperStyles.widthHtmlContent)}
        >
          <h2 id="company">Company</h2>
          <p>
            Userbrain is an online user testing tool, backed by thousands of
            testers, that will let you know what’s working for your product —
            and what’s not. With fast, simple, and affordable user tests, we
            empower designers, UX experts, founders, and marketers to build
            products people love to use.
          </p>
          <p>
            Our customer list includes big brands like: Spotify, Virgin, Audi
            and Tesla to name just a few.
          </p>
          <p>
            We are seeking a part-time customer support specialist to assist our
            customers when using our user testing service.
          </p>

          <h2 id="job">Job</h2>
          <ul>
            <li>Salary negotiable</li>
            <li>Part-time</li>
            <li>100% remote</li>
          </ul>

          <h2 id="responsibilities">
            <strong>Responsibilities</strong>
          </h2>
          <ul>
            <li>
              Respond to customer queries in a timely, friendly, and accurate
              way via email, chat, or phone.
            </li>
            <li>
              Identify customer needs and help customers use specific features.
            </li>
            <li>
              Update our internal knowledge bases with information about new
              features and other product changes.
            </li>
            <li>
              Monitor customer feedback and/or complaints and reach out to
              provide assistance.
            </li>
            <li>Keep track of customer expectations and recurring issues.</li>
            <li>
              Share feature requests and effective workarounds with team
              members.
            </li>
            <li>
              Collaborate on a daily basis with your colleagues and update them
              on any developments.
            </li>
          </ul>
          <h2 id="requirements">
            <strong>Requirements</strong>
          </h2>
          <p>
            This opportunity is so much more than customer service. Using chats,
            email and phone calls, you’ll be here to listen, teach, problem
            solve and explore user testing opportunities with some of the
            biggest brands in the world. You will make customers feel empowered
            after interacting with support.
          </p>
          <ul>
            <li>
              12-18 months experience as a Customer Support Specialist or
              similar CS role
            </li>
            <li>
              Experience using help desk software and remote support tools
            </li>
            <li>Patience when handling tough cases</li>
            <li>High level of self-motivation</li>
            <li>Interest or background in User Experience</li>
            <li>Comfortable working remotely from your own initiative</li>
            <li>Evidence of working towards objectives and achieving goals</li>
            <li>
              Confident telephone manner with excellent communication skills
              (verbal and written)
            </li>
            <li>
              We are looking for someone who can work to the UTC Time Zone.
            </li>
          </ul>
          <h2 id="desirable">
            <strong>Desirable</strong>
          </h2>
          <ul>
            <li>Experience selling SaaS solutions</li>
            <li>
              Experience with products related to User Experience or User
              Testing
            </li>
          </ul>
          <h2 id="perks">Perks</h2>
          <ul>
            <li>
              Freedom to live wherever you like so long as you have reliable,
              fast internet and a permanent residence with the ability to work
              predominantly PST hours.
            </li>
            <li>Largely PST business hours but you manage your day.</li>
            <li>
              At least 1 x per year, you will enjoy an all-expenses-paid team
              trip to an exciting location in Europe (last trip has been a 14
              day all-expenses-paid journey to Lisbon)
            </li>
            <li>Significant professional development opportunities</li>
            <li>
              Opportunity to go to conferences and other networking events
            </li>
          </ul>
          <p>
            <em>
              If this sounds like a challenge for you, then please apply today!
            </em>
          </p>

          <p style={{ marginTop: "1.5rem" }}>
            <a
              className="btn btn-default"
              href="https://www.surveymonkey.com/r/TNCNQMM"
            >
              Apply now
            </a>
          </p>
        </HtmlContent>
      </div>
    </article>
  </Layout>
);

export default Sales;
